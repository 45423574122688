<template>
  <div class="group-question-wrapper">
    <div class="add-btn-wrapper">
      <h3>Danh sách nhóm câu hỏi</h3>
      <div class="add-btn">
        <v-btn color="primary" @click="viewGroupQuestion()"
        >Tạo nhóm câu hỏi mới
        </v-btn>
      </div>
    </div>
    <v-alert v-if="groupQuestions.length === 0" :value="true" color="warning" icon="priority_high" outlined>
      Chưa có dữ liệu hiển thị
    </v-alert>
    <v-row class="mt-10">
      <v-col>
        <v-text-field
          outlined
          v-model="title"
          hide-details
          dense
          rounded
          ref="search_title"
          color="#158FB6"
          placeholder="Tên nhóm"
      >
      </v-text-field>
      </v-col>
      <v-col>
        <v-text-field
            outlined
            v-model="part_id"
            hide-details
            dense
            rounded
            ref="search_part_id"
            color="#158FB6"
            placeholder="Part"
        >
        </v-text-field>
      </v-col>
      <v-col>
        <v-text-field
            outlined
            v-model="from"
            hide-details
            dense
            rounded
            ref="search_from"
            color="#158FB6"
            placeholder="Từ"
        >
        </v-text-field>
      </v-col>
      <v-col>
        <v-text-field
            outlined
            v-model="to"
            hide-details
            dense
            rounded
            ref="search_to"
            color="#158FB6"
            placeholder="Đến"
        >
        </v-text-field>
      </v-col>
      <v-col>
        <hnr-button
          :text="'Search'"
          :color="'primary'"
          :rounded=true
          @hnrClickEvent="Search"
        ></hnr-button>
      </v-col>
    </v-row>

  <v-data-table :headers="headers"
                :items="groupQuestions"
                item-key="id"
                class="elevation-1 custom-size-img"
                >
    <template v-slot:item.title="{ item }">
      <div v-html="item.title.length>250?(item.title.substr(0,250)+'...'):item.title"></div>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon
          small
          class="mr-2"
          @click="viewGroupQuestion(item)"
      >
        mdi-pencil
      </v-icon>
      <v-icon
          small
          @click="deleteGroupQuestion(item)"
      >
        mdi-delete
      </v-icon>
    </template>
    <template v-slot:item.list="{ item }">
      <v-btn
          small
          class="mr-2"
          @click="viewListQuestion(item)"
      >
        Xem danh sách câu hỏi
      </v-btn>
    </template>
  </v-data-table>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {
  GET_GROUP_QUESTION_LIST_BY_CONFIG,
    DELETE_GROUP_QUESTION,
} from "@/store/group-questions.module";
import HnrButton from "@/components/elements/hnr-button"

export default {
  components: {
    HnrButton
  },
  data() {
    return {
      title: '',
      part_id: '',
      from: '',
      to: '',
      exam_config_id: this.$route.query.exam_config_id,
      headers: [
        {text: "Tên nhóm", value: "title"},
        {text: "Loại part", value: "part_id"},
        {text: "Vị trí nhóm câu hỏi", value: "questionGroupNum"},
        {text: "Từ", value: "from"},
        {text: "Đến", value: "to"},
        {text: "Số lượng câu hỏi", value: "questions_count"},
        {text: "Ngày tạo", value: "created_date"},
        {text: "Thao tác ", value: "actions"},
        {text: "", value: "list"},
      ],
    };
  },
  computed: {
    ...mapGetters({
      groupQuestions: "groupQuestionListByConfig",
    }),
  },
  async created() {
    await this.fetchGroupQuestion();
  },

  methods: {
    Search() {
      this.$store.dispatch(GET_GROUP_QUESTION_LIST_BY_CONFIG, {
        title: this.title,
        part_id:  this.part_id,
        from:  this.from,
        to:  this.to,
        exam_config_id: this.exam_config_id
      });
      this.title = ''
      this.part_id = ''
      this.from = ''
      this.to = ''
    },
    fetchGroupQuestion(){
       this.$store.dispatch(GET_GROUP_QUESTION_LIST_BY_CONFIG, {
        exam_config_id: this.exam_config_id
      });
    },
    viewGroupQuestion(item) {
      let routeData = null;
      if (item) {
        routeData = this.$router.resolve({
          path: "/group-question/detail",
          query: {
            exam_config_id: this.exam_config_id,
            group_question_id: item.id
          },
        });
      } else {
        routeData = this.$router.resolve({
          path: "/group-question/detail",
          query: {exam_config_id: this.exam_config_id},
        });
      }
      window.open(routeData.href, '_self');
    },
    deleteGroupQuestion(groupQuestion) {
      confirm("Bạn có chắc chắn muốn xóa không?") &&
      this.$store
          .dispatch(DELETE_GROUP_QUESTION,  groupQuestion.id)
          .then((data) => {
            if (data.status) {
              this.fetchGroupQuestion()
            }
          });
    },
    viewListQuestion(item) {
      let routeData = this.$router.resolve({
        path: "/question/list",
        query: {
          part_id: item.part_id,
          exam_config_id: this.exam_config_id,
          group_question_id: item.id,
        },
      });
      window.open(routeData.href, '_self');
    }
  }
};
</script>


<style lang="scss">
.group-question-wrapper {
  padding: 20px;
}

.add-btn-wrapper {
  display: grid;
  grid-template-columns: auto auto;
  margin-bottom: 10px;

  .add-btn {
    display: flex;
    justify-content: flex-end;

    button {
      width: 200px;
    }
  }
}
.custom-size-img {
  img {
    margin-top: 20px;
    width: 300px;
  }
}
</style>
